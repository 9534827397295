.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0px;
  width: 100vw;
}

.map {
  height: 100%;
}

.new-slipway-map {
  cursor: crosshair;
}

.form-bottom-margin {
  margin-bottom: 20px
}

.slipway-img-max-width-height {
  max-width: 80vw;
  max-height: 50vh;
}

.photos-div {
  border: 1px solid;
  border-radius: 16px;
}

.slipway-img {
  max-height: 50vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.carousel-item {
  background-color: #87CEFA;
}

.login-form {
  text-align: center;
  margin: auto;
}

.small-text {
  font-size: 14px;
}

form {
  max-width: 500px;
}

.card {
  margin: 10px
}
